import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import classes from "./PaidBook.module.css";
import cartImage from "../../asset/cart-sm.svg";
import gep from "../../asset/gep.png";
import christosinpen from "../../asset/christosinpen.png";

const bookStore = [
  {
    id: "d1",
    src: gep,
    name: "God's Eternal Plan",
    price: 4000,
    cart: cartImage,
    orderLink: "https://bit.ly/Edenorder", 
  },
  {
    id: "d2",
    src: christosinpen,
    name: "Christosin's Pen",
    price: 2000,
    cart: cartImage,
    orderLink: "https://bit.ly/ChristosinPenOrder", 
  },
];

const HeroThreeSection = () => {
  const addToCartHandler = (orderLink) => {
    // Redirect to the Google Form
    window.location.href = orderLink;
  };

  const bookList1 = bookStore.map((book) => (
    <Col lg={6} md={6} sm={12} className={classes.dish_col} key={book.id}>
      <div className={classes.book_card}>
        <img src={book.src} alt={book.name} className={classes.book_image} />
        <div className={classes.book_details}>
          <h5>{book.name}</h5>
          <div className={classes.price_and_cart}>
            <p>NGN {book.price.toLocaleString()}</p>
            <span>|</span>
            <button 
              className={classes.add_to_cart}
              onClick={() => addToCartHandler(book.orderLink)} // Handle redirection to Google Form
            >
              Order
              <img src={book.cart} alt="Add to cart" />
            </button>
          </div>
        </div>
      </div>
    </Col>
  ));

  return (
    <section id="dishes">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12}>
            <div className={classes.header_div}>
              <h2>Paid Books</h2>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {bookList1}
        </Row>
      </Container>
    </section>
  );
};

export default HeroThreeSection;
