import React, { useState, useEffect } from 'react';
import { Row, Container, Col } from "react-bootstrap";
import TheButton from "../../src/components/Ui/TheButton";
// import btn from '../../src/components/Ui'
import "bootstrap/dist/css/bootstrap.min.css";
// import classes from '../../src/components/SectionComponents'
// import classes from "../../src/components/Section/HeroFourSection.module.css";
import classes from "../components/Sections/HeroFourSection.module.css";

import aboutImage from "../assets/image/about-image.webp";
import Modal from "../../src/components/Ui/Modal";
import kerusso from '../assets/image/elias_out.jpg'
import apple from '../assets/image/apple_logo.png'
import spotify from '../assets/image/spotify_logo.png'
import audiomack from '../assets/image/audiomack_logo.png'
import youtube from '../assets/image/Youtube_logo.png'
import { FaDownload, FaPlay } from 'react-icons/fa';
import classesBtn from "./Eliass.module.css";
import Footer from '../components/Footer/Footer'
import TheFooter from '../components/Footer/TheFooter'

const Elias = () => {
  const [showModal, setShowModal] = useState(false);
  const streamingServices = [
    { name: "YouTube", link: "https://youtu.be/3jc1qBQdqdE?si=xslVDo4jXTfG4haC", icon: youtube },
    { name: "Spotify", link: "https://open.spotify.com/album/0STtN2bJvBVAVIzOun0vsn?si=wblrhLxbR1GTFpkGUFccNQ", icon: spotify },
    { name: "Apple Music", link: "https://music.apple.com/gb/album/elias-man-of-fire-feat-onestringz/1743152639?i=1743152640", icon: apple },
    { name: "Audiomack", link: "https://audiomack.com/pastorchristosin/song/elias-man-of-fire-1", icon: audiomack },
    // Add more services as needed
  ];
  const [responsiveStyle, setResponsiveStyle] = useState({ marginTop: '10%' });

  const aboutModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    function handleResize() {
        if (window.innerWidth <= 480) {
            setResponsiveStyle({ marginTop: '16%' });
        } else if (window.innerWidth <= 768) {
            setResponsiveStyle({ marginTop: '25%' });
        } else {
            setResponsiveStyle({ marginTop: '10%' });
        }
    }

    // Set the initial style
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
}, []);


  //Rendering the About section and the About Modal
  return (
    <>
      
      <section className={classes.rower} id="elias">

        <Container>
        <Row style={responsiveStyle} className={`${classes.row} mx-auto`}>
            <Col
              lg={6}
              data-aos="fade-right"
              data-aos-easing="ease-out"
              data-aos-duration="700"
            >
              <div className={classes.text_div}>
              <h2>NOW AVAILABLE FOR DOWNLOAD</h2>

                {/* <h2 className={classes.text_header}>About us</h2> */}
                <p className={classes.text_content}>
                The Wait is Over! 

🎼 Pastor <a href="https://www.youtube.com/@pastorchristosinolalere"> Christosin Olalere's</a> newly released single "ELIAS - MAN OF FIRE" is now available on streaming platforms and for downloads. 

Experience divine inspiration and Spirit filled worship as you listen to ELIAS- MAN OF FIRE. 

<a href="https://www.heraldsnation.org"> @HeraldsNation</a>, we uphold the culture of prayer and this ELIAS is designed to lead you into a lifetime experience in prayer! 

This song is the game-changer for your prayer life. 

Download NOW! 

                </p>
                <div className={classes.button_div}>
                  {/* <TheButton onClick={aboutModal}>See more</TheButton> */}
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div
                className={classes.image_div}
                data-aos="fade-left"
                data-aos-easing="ease-out"
                data-aos-duration="700"
              >
                <img
                  className={classes.image}
                  src={kerusso}
                  alt="about"
                ></img>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
  <Col lg={12} className="text-center">
  <button className={`${classesBtn.joinButton} ${classesBtn.downloadButton}`} onClick={() => window.location.href = 'https://vanguardbackend-pih0.onrender.com/download/audio-1715901727267.mp3'}>
  <FaDownload /> DOWNLOAD
</button>

<button className={`${classesBtn.joinButton} ${classesBtn.listenButton}`} onClick={() => window.location.href = 'https://push.fm/fl/christosinolalere-man-of-fire'}>
  <FaPlay /> Listen Here
</button>

  </Col>
</Row>
          <Row className={`${classes.row} mx-auto`}>
            <Col>
              <div className={classes.streamingButtons}>
                {streamingServices.map((service, index) => (
                  <a
                    key={index}
                    href={service.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.streamingButton}
                  >
                    <img src={service.icon} alt={service.name} />
                  </a>
                ))}
              </div>
            </Col>
          </Row>

        </Container>
        <TheFooter />

      </section>
    </>
  );
  //END
};

export default Elias;
