import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import classes from "./HeroFiveSection.module.css";
import mobileImage from "../../assets/image/mobile.webp";
import playStore from "../../assets/image/playstore.png";
import appStore from "../../assets/image/appstore.png";
import Tabs from "./Tabs";
import Panel from "./Panel";
import BookDownloadsContent from "../SectionComponents/BookDownloadsContent";
import gep from "../../asset/gep.jpg";
import tdos from "../../asset/tdos.jpg"

import download from "../../asset/download-btn.svg";
import chm from "../books/CHM102_WEEK1-7.pdf";
import chm1 from "../../";
const bookStore = [
  {
    id: "d1",
    src: tdos,
    name: "TDOF & TFOS",
    download: "Download",
    cart: download,
    readmore: "Read More",
    pdf: "TDOS-TFOS.pdf"
  },

  {
    id: "d2",
    src: gep,
    name: "GOD's Eternal Plan",
    download: "Download",
    cart: download,
    readmore: "Read More",
    pdf: ""
  },

  {
    id: "d3",
    src: gep,
    name: "Everything I Don't Want",
    download: "Download",
    cart: download,
    readmore: "Read More",
    pdf: "Everything I Don't Want (Overcoming Tempations) - Christosin Olalere.pdf"
  },

  {
    id: "d4",
    src: gep,
    name: "Consecration",
    download: "Download",
    cart: download,
    readmore: "Read More",
    pdf: "Consecration - Christosin Olalere.pdf"
  },

 ]


const bookStore2 = [
  {
    id: "d1",
    src: gep,
    name: "When Momma Says No!",
    download: "Download",
    cart: download,
    readmore: "Read More",
    pdf: "When Momma Says No! - Christosin Olalere.pdf"
  },

  {
    id: "d1",
    src: gep,
    name: "Why You Act The Way You Do",
    download: "Download",
    cart: download,
    readmore: "Read More",
    pdf: "Why You Act The Way You Do - Christosin Olalere.pdf"
  }

]

const bookList1 = bookStore.map((book) => (
  <Col lg={3} className={classes.dish_col}>
    <div
      data-aos="fade-up"
      data-aos-easing="ease-out"
      data-aos-duration="700"
    >
      <BookDownloadsContent
        key={book.id}
        id={book.id}
        name={book.name}
        src={book.src}
        download={book.download}
        cart={book.cart}
        readmore={book.readmore}
        pdf={book.pdf}
      />
    </div>
  </Col>
));

const bookList2 = bookStore2.map((book) => (
  <Col lg={3} className={classes.dish_col}>
    <div
      data-aos="fade-up"
      data-aos-easing="ease-out"
      data-aos-duration="700"
    >
      <BookDownloadsContent
        key={book.id}
        id={book.id}
        name={book.name}
        src={book.src}
        download={book.download}
        cart={book.cart}
        readmore={book.readmore}
        pdf={book.pdf}
      />
    </div>
  </Col>
));

const HeroFiveSection = () => {
  //Structure % layout of the get our mobile app
  return (
    <section id="mobile-app">
      <Container>
        <Row
          className={`${classes.row} mx-auto`}
          data-aos="fade-up"
          data-aos-easing="ease-out"
          data-aos-duration="700"
        >
          <Col xs={12}>
            <div className={classes.header_cdiv}>
              <h2>Pastor's Books</h2>
            </div>
          </Col>
        </Row>

        <Row className={`${classes.row_content} mx-auto`}>
          <Col
            lg={6}
            data-aos="fade-right"
            data-aos-easing="ease-out"
            data-aos-duration="700"
          >
            <div className={classes.text_div}>
                
                 <div className={classes.action_div}>
                              
                  </div>
            </div>
          </Col>
          <Col
            lg={6}
            data-aos="fade-left"
            data-aos-easing="ease-out"
            data-aos-duration="700"
          >
            
          </Col>
        </Row>
        <Tabs>
      <Panel title="English">
        <Row className={classes.row_dish}>{bookList1}</Row>
        <Row className={classes.row_dish}>{bookList2}</Row>

      </Panel>

      <Panel title="French">
      <Row className={classes.row2_dish}>{bookList1}</Row>
      </Panel>

      <Panel title="Korean">
      <Row className={classes.row_dish}>{bookList1}</Row>
     </Panel>
     
    </Tabs>
      </Container>
    </section>
  );
  //END
};

export default HeroFiveSection;
