import React from "react";
import { Link } from "react-router-dom";
import classes from './services.module.css';

export default function Card(props) {
  return (
    <div className={classes.bookContainer}>
        <img className={classes.bookImg} src={props.bookImage} alt="book"/>
        <p className={classes.bookName}>{props.name}</p>
      <Link className={classes.bookDownload} to={`/ebook/${props.id}`}>{props.download}</Link>
      {/* <Link to={`/ebook/${props.id}`}>{props.name}Read More</Link> */}

    </div>
  );
}
