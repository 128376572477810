import React from "react";
import servicedata from "./servicedata";
import Card from "./Services";

export default function ServiceList() {
  const list = servicedata.map((item) => {
    return <Card 
    key={item._id} 
    id={item._id} 
    name={item.name} 
    price={item.price}
    bookImage={item.bookImage}
    download={item.download}/>;
  });
  return <div>{list}</div>;
}
