import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import classes from "./media.module.css";
import MediaContent from "./MediaContent";
import Tabs from "./MediaTab";
import Panel from "../components/Sections/Panel";
import "./styles.css"
import gep from "../asset/gep.jpg"
import Tab from "../components/Sections/Tabs"
import PanelMedia from "../components/Sections/Panel"
import ComingSoonSection from "../components/Sections/ComingSoonSection";
import HeroTwoSection from "../components/Sections/HeroTwoSection";
import HeroThreeSection from "../components/Sections/HeroThreeSection";
import Footer from '../components/Footer/Footer'
import TheFooter from '../components/Footer/TheFooter'
import { FaDownload, FaPlay } from 'react-icons/fa';
import classesBtn from "./Eliass.module.css";
import { Link } from "react-router-dom";
import downloadIcon from '../assets/icons/download.svg'
import streamIcon from '../assets/icons/stream.svg'
import purpose from '../assets/image/purpose.jpg'
import anointed from '../assets/image/annointed_jesus.jpeg'
import direction from '../assets/image/flames_of_direction.jpeg'
import footstool from '../assets/image/footstool.jpeg'
import healer from '../assets/image/great_healer.jpeg'

const text = `This is one of the greatest \n Bible studies ever done! It is a Bible Course material that will challenge you to more Bible Study, and change your understanding of the word forever! To understand GOD's Eternal Plan, you have to observe what was said in the opening words of the Bible, hence this Book, which carries a very robust study of Genesis.In this comprehensive study material, you will be totally transformed by the light of the revelation of GOD's Eternal Plan for Mankind.`


//Data objects to be used in the HeroThree section for rendering dishes information
// const dummyListone = [
//   {
//     id: "d1",
//     src: gep,
//     name: "God's Eternal Plan",
//     description: text,
//     price: 3000,
//   },

 
// ];
//END

const MediaSection = () => {
  //Mapping the data from the object above and storing them in a variable
  // const dishList1 = dummyListone.map((dish) => (
  //   <Col lg={4} className={classes.dish_col}>
  //     <div
  //       data-aos="fade-up"
  //       data-aos-easing="ease-out"
  //       data-aos-duration="700"
  //     >
  //       <MediaContent
  //         key={dish.id}
  //         id={dish.id}
  //         name={dish.name}
  //         src={dish.src}
  //         price={dish.price}
  //         description={dish.description}
  //       />
  //     </div>
  //   </Col>
  // ));

  const songs = [

    {
      id: 1,
      title: "PENTECOST - The Harvest",
      artist: "Pastor Christosin Olalere",
      imageUrl: require('../assets/image/Pentecost.png'), // Adjust path as necessary
      streamUrl: "https://example.com/stream/elias",
      downloadUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1717404895170.mp3"
    },

    {
      id: 2,
      title: "EL DOXA - God of Glory",
      artist: "Pastor Christosin Olalere",
      imageUrl: require('../assets/image/eldoxadd.jpg'), // Adjust path as necessary
      streamUrl: "https://example.com/stream/elias",
      downloadUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1715901590522.mp3"
    },
    {
      id: 3,
      title: "ELIAS - Man of Fire",
      artist: "Pastor Christosin Olalere",
      imageUrl: require('../assets/image/elias_main.jpg'), // Adjust path as necessary
      streamUrl: "https://example.com/stream/eldoax",
      downloadUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1715901727267.mp3"
    },
    { id: 4,
       title: "ACT ON PURPOSE",
        artist: "Pastor Christosin Olalere", 
        imageUrl: purpose, 
        songUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1715901495633.mp3", 
        buttonType: "download",
        downloadUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1715901495633.mp3"
      },
      { id: 5,
        title: "ANOINTED JESUS",
         artist: "Pastor Christosin Olalere", 
         imageUrl: anointed, 
         songUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1718868489593.mp3", 
         buttonType: "download",
         downloadUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1718868489593.mp3"
       },
       { id: 6,
        title: "FLAMES OF DIRECTION",
         artist: "Pastor Christosin Olalere", 
         imageUrl: direction, 
         songUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1718868645096.mp3", 
         buttonType: "download",
         downloadUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1718868645096.mp3"
       },
       { id: 7,
        title: "GREAT HEALER",
         artist: "Pastor Christosin Olalere", 
         imageUrl: healer, 
         songUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1718868573330.mp3", 
         buttonType: "download",
         downloadUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1718868573330.mp3"
       },
       { id: 8,
        title: "FOOTSTOOL",
         artist: "Pastor Christosin Olalere", 
         imageUrl: footstool, 
         songUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1715901495633.mp3", 
         buttonType: "download",
         downloadUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1719067429474.mp3"
       },

// AOP: audio-1715901495633.mp3
//GOG:  audio-1715901590522.mp3
//ELIAS: audio-1715901727267.mp3

    // {
    //   id: 3,
    //   title: "ELIAS - Man of Fire",
    //   artist: "Pastor Christosin Olalere",
    //   imageUrl: require('../assets/image/eldoxadd.jpg'), // Adjust path as necessary
    //   streamUrl: "https://example.com/stream/elias",
    //   downloadUrl: "https://example.com/download/elias"
    // },
    // Add more songs as needed
  ];


  return (
    <section id="media" className={classes.mediaSection}>
      <Container>
      <h2 className="text-center mt-4 mb-5">Songs Alert!</h2>

        <Row
          className={`${classes.row} mx-auto`}
          data-aos="fade-up"
          data-aos-easing="ease-out"
          data-aos-duration="700"
        >
          <Row className="justify-content-center">
          <div className={classes.scrollableRow}>
          {songs.map(song => (
            <div key={song.id} className={classes.albumCard}>
              <img src={song.imageUrl} alt={song.title} className={classes.albumImage}/>
              <div className={classes.albumInfo}>
                <button className={`${classes.button} ${classes.downloadButton}`} onClick={() => window.location.href = song.downloadUrl}>
                  <img src={downloadIcon} alt="Download" className={classes.icon}/> Download Now
                </button>
              </div>
            </div>
          ))}
        </div>

        </Row>

          

        </Row>
        {/* <Col lg={12} className="text-center">
  <button className={`${classesBtn.joinButton} ${classesBtn.downloadButton}`} onClick={() => window.location.href = '/elias-man-of-fire'}>
  <FaDownload /> DOWNLOAD ELIAS (MAN OF FIRE)
</button>

<button className={`${classesBtn.joinButton} ${classesBtn.listenButton}`} onClick={() => window.location.href = 'https://push.fm/fl/christosinolalere-man-of-fire'}>
  <FaPlay /> Listen Here
</button>

  </Col> */}

        {/* <Row className={classes.row_dish}>{dishList1}</Row>

        <Row>{dishList2}</Row> */}
        <div className={classes.tab_col}>
        {/* <HeroTwoSection /> */}
      <HeroThreeSection />
     
    </div>

   

      </Container>
      <TheFooter />

    </section>
  );
  //END
};

export default MediaSection;
