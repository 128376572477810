import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import classes from "./Footer.module.css";
import Logo from "../../assets/Logo/Logo.svg";
import { Link } from "react-scroll";
import twitter from "../../asset/twitter.svg";
import telegram from "../../asset/telegram.svg";
import fb from "../../asset/fb.svg";
import instagram from "../../asset/fb.svg";
import youtube from "../../asset/youtube.svg";

import teaching from "../../asset/teaching.svg";
import arrow from "../../asset/arrow.svg";
import footerLogo from "../../asset/footer-logo.svg";

const Footer = () => {
  //Structure & layout of the footer
  return (
    <div className={classes.footer_bg}>
      <Container>
        <Row className={classes.row}>
          <Col lg={4}>
            <div className={classes.info}>
              <div className={classes.image_div}>
                <Link
                  to="hero"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                >
                  <h2 className={classes.reach_out}>Reach out</h2>
                  <div className={classes.divider_line}></div>
                </Link>
              </div>
              <div className={classes.content_div}>
                <div className={classes.social_btn}>
                  <img src={teaching} alt="teaching" />
                  <a
                    href="http://bit.ly/HICCmedia"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p> Teachings </p>
                  </a>
                  <img src={arrow} alt="arrow" />
                  <div className={classes.social_divider}></div>
                </div>

                <div className={classes.social_btn}>
                  <img src={twitter} alt="twitter" />
                  <a
                    href="https://twitter.com/christosin01?s=21&t=Bpl8kEvoK8pTi_FshCdKzg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p> Twitter </p>
                  </a>
                  <img src={arrow} alt="arrow" />
                  <div className={classes.social_divider}></div>
                </div>

                <div className={classes.social_btn}>
                  <img src={fb} alt="teaching" />
                  <a
                    href="#"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p> Facebook </p>
                  </a>
                  <img src={arrow} alt="arrow" />
                  <div className={classes.social_divider}></div>
                </div>

                <div className={classes.social_btn}>
                  <img src={instagram} alt="ig" />
                  <a
                    href="#"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p> Instagram </p>
                  </a>
                  <img src={arrow} alt="arrow" />
                  <div className={classes.social_divider}></div>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={2}>
            <Link
              to="hero"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            ></Link>
            <div className={classes.content_div2}>
              <div className={classes.social_btn}>
                <img src={youtube} alt="youtube" />
                <a
                  href="https://youtube.com/channel/UCcRuxnEo5NvtyLDaRMonclA"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p> Youtube </p>
                </a>
                <img src={arrow} alt="arrow" />
                <div className={classes.social_divider}></div>
              </div>

              <div className={classes.social_btn}>
                <img src={telegram} alt="telegram" />
                <a
                  href="http://t.me/ChristosinClips"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p> Telegram </p>
                </a>
                <img src={arrow} alt="arrow" />
                <div className={classes.social_divider}></div>
              </div>
            </div>
          </Col>

          <div className={classes.footer_down}>
            <img src={footerLogo} alt="logo-down" />

            <p className={classes.mobile_copy}>
              &copy;2024 All right Reserved&nbsp;
              <a
                href="#"
                target="_blank"
                rel="noreferrer"
                className={classes.footer_codes}
              ></a>
            </p>
          </div>
        </Row>
      </Container>
    </div>
  );
  //END
};

export default Footer;
