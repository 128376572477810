import { useParams } from "react-router-dom";
import servicedata from "./servicedata";

export default function ServiceDetails() {
  const { param } = useParams();
  console.log("url:",param);
  const clickedOne = servicedata.find((item) => item._id === param);
  console.log("clickedone",clickedOne);
  return (
    <div style={{
      marginTop: "100px"
    }}>
      <h1>{clickedOne.name}</h1>
      <p>{clickedOne.description}</p>
      <p >{clickedOne.price}</p>
      <a href={clickedOne.pdf}>Download Book</a>
    </div>
  );
}
