
 export const data = [
    {
      id: 1,	
      name: 'Hong Kong Disneyland',
      category: 'Entertainment'
  }, 
  {
      id: 2,		
      name: 'Repulse Bay and the Beaches',
      category: 'Outdoor'
  }, 
  {
      id: 3,
      name: 'Star Ferry Pier/Victoria Harbour',
      category: 'Attraction'
  }
]

