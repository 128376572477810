import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import CartContext from "../store/cartcontext";
import classes from "./CheckoutForm.module.css";
import { PaystackButton } from "react-paystack";
import paystack from '../../asset/paystack-secured.svg';

const CheckoutForm = () => {
  const location = useLocation();
  const { shippingState, shippingCost } = location.state || {};

  // Get cart context
  const cartCtx = useContext(CartContext);
  const totalCartAmount = cartCtx.totalAmount;

  // State to handle form inputs and errors
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    streetAddress: "",
    city: "",
    phone: "",
    email: "",
    additionalInfo: ""
  });

  const [errors, setErrors] = useState({});

  // Validate form inputs
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.firstName) newErrors.firstName = "First name is required.";
    if (!formData.lastName) newErrors.lastName = "Last name is required.";
    if (!formData.streetAddress) newErrors.streetAddress = "House number and street name are required.";
    if (!formData.city) newErrors.city = "Town/City is required.";
    if (!formData.email) newErrors.email = "Email address is required.";

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      // Proceed to Paystack payment
      // The payment process will be handled by the PaystackButton component
      console.log("Form data:", formData);
      setErrors({});
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const config = {
    reference: new Date().getTime().toString(),
    email: formData.email,
    amount: (totalCartAmount + shippingCost) * 100, // Amount in kobo
    publicKey: "pk_live_69b8c659bb0d7633ba37341713cb5400f2d7ff01", // Replace with your Paystack public key
  };

  const handlePaystackSuccessAction = (reference) => {
    // Handle successful payment
    console.log(reference);
    // You can also redirect the user to a success page or display a success message
  };

  const handlePaystackCloseAction = () => {
    // Handle the case where the payment is closed or cancelled
    console.log("Payment closed");
  };

  const componentProps = {
    ...config,
    text: "Pay with Paystack",
    onSuccess: handlePaystackSuccessAction,
    onClose: handlePaystackCloseAction,
  };

  return (
    <div className={classes.checkoutContainer}>
      <div className={classes.checkoutHeader}>
        Complete your checkout, by filling your delivery details here
      </div>  
      <form onSubmit={handleSubmit}>
        <div className={classes.inputGroup}>
          <label>First name*</label>
          <input 
            type="text" 
            name="firstName" 
            value={formData.firstName} 
            onChange={handleChange} 
            required 
          />
          {errors.firstName && <p className={classes.error}>{errors.firstName}</p>}
        </div>
        <div className={classes.inputGroup}>
          <label>Last name*</label>
          <input 
            type="text" 
            name="lastName" 
            value={formData.lastName} 
            onChange={handleChange} 
            required 
          />
          {errors.lastName && <p className={classes.error}>{errors.lastName}</p>}
        </div>
        <div className={classes.inputGroup}>
          <label>House number and street name*</label>
          <input 
            type="text" 
            name="streetAddress" 
            value={formData.streetAddress} 
            onChange={handleChange} 
            required 
          />
          {errors.streetAddress && <p className={classes.error}>{errors.streetAddress}</p>}
        </div>
        <div className={classes.inputGroup}>
          <label>Town/City*</label>
          <input 
            type="text" 
            name="city" 
            value={formData.city} 
            onChange={handleChange} 
            required 
          />
          {errors.city && <p className={classes.error}>{errors.city}</p>}
        </div>
        <div className={classes.inputGroup}>
          <label>State*</label>
          <input type="text" value={shippingState || ''} readOnly />
        </div>
        <div className={classes.inputGroup}>
          <label>Phone number (Optional)</label>
          <input 
            type="text" 
            name="phone" 
            value={formData.phone} 
            onChange={handleChange} 
          />
        </div>
        <div className={classes.inputGroup}>
          <label>Email address*</label>
          <input 
            type="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            required 
          />
          {errors.email && <p className={classes.error}>{errors.email}</p>}
        </div>
        <div className={classes.inputGroup}>
          <label>Additional order information (Optional)</label>
          <textarea 
            name="additionalInfo" 
            value={formData.additionalInfo} 
            onChange={handleChange} 
          />
        </div>
        {/* Use PaystackButton instead of a regular button */}
        <PaystackButton className={classes.placeOrderButton} {...componentProps} />
      </form>

      {/* Order Summary Section */}
      <div className={classes.orderSummary}>
        <div className={classes.paymentOptions}>
          <img src={paystack} alt="Paystack" />
          {/* Add your payment options icons here */}
        </div>
        <div className={classes.summaryDetails}>
          <div className={classes.summaryText}>
            <p>Subtotal</p>
            <p>NGN {totalCartAmount.toLocaleString()}</p>
          </div>
          <div className={classes.summaryText}>
            <p>Shipping</p>
            <p>NGN {shippingCost.toLocaleString()}</p> {/* Adjust this based on shipping logic */}
          </div>
        </div>
        {cartCtx.items.map((item) => (
          <div key={item.id} className={classes.bookDetails}>
            <img src={item.src} alt={item.name} />
            <div className={classes.bookInfo}>
              <p>{item.name}</p>
              <p>Quantity: {item.amount}</p>
              <p>NGN {item.price.toLocaleString()}</p>
            </div>
          </div>
        ))}
        <div className={classes.summaryDetails}>
          <div className={classes.summaryText}>
            <p>Total</p>
            <p>NGN {(totalCartAmount + shippingCost).toLocaleString()}</p> {/* Adjust shipping cost dynamically */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
