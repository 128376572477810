import React from 'react';
import styles from './NotFound.module.css'; // Adjust the path based on your file structure

export const NotFound = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>404 - Page Not Found</h1>
      <p className={styles.text}>The page you are looking for does not exist.</p>
    </div>
  );
};
