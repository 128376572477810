import React, { useState } from "react";
import { Row, Container, Col } from "react-bootstrap";
import TheButton from "../components/Ui/TheButton";
import "bootstrap/dist/css/bootstrap.min.css";
import classes from "./HeroFourSection.module.css";
// import aboutImage from "../../assets/image/about-image.webp";
import Modal from "../components/Ui/Modal";
import aboutImage from "../assets/image/about-image.webp";
import pastorImage from "../asset/vanguard-home.svg"
import Footer from "../components/Footer/Footer";
const HeroFourSection = () => {
  const [showModal, setShowModal] = useState(false);

  const aboutModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  //Rendering the About section and the About Modal
  return (
    <>
      {showModal && (
        <Modal>
          <div className={classes.about_modal}>
            <div className={classes.about_header}>
              <h2 className={classes.text_modal_header}>About us</h2>
            </div>
            <div className={classes.about_body}>
             
            </div>
            <div className={classes.about_footer}>
              <div className={classes.button_modal_div}>
                <TheButton onClick={closeModal}>Cancel</TheButton>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <section >
        <Container>
          <Row className={`${classes.row} mx-auto`}>
            <Col
              lg={6}
              data-aos="fade-right"
              data-aos-easing="ease-out"
              data-aos-duration="700"
            >
              <div className={classes.text_div}>
                <h2 className={classes.text_header}>Get to know <br></br>Pastor Christosin Olalere</h2>
                <p className={classes.text_content}>
                In over 10 years of ministry, and saying YES to the call of GOD, one thing has been a strong and ever-present part of Pastor Christosin's life in ministry. That is the Pastoral grace which he carries. <br></br> <br></br>

Pastor Christosin is a true shepherd at heart and has received a mandate from GOD to raise the total man in Christ, one who will rule in heaven and earth, in things spiritual and natural.  <br></br><br></br>

This has been seen in his keen interest and attention to the details of the life of GOD’s people, tending to their physical lives while also ensuring proper spiritual growth for them. <br></br> <br></br>

Pastor Christosin Olalere is the Founder and Vanguard of Heralds International Christian Centre, with headquarters in Uyo, Nigeria where he pastors alongside his wife, Pastor Nimi Christosin-Olalere. <br></br> <br></br>

He is a Business and Data Analyst with a strong educational administration background, willing to nurture and mentor young minds, and ensure they maximize their potentials. <br></br> <br></br>

He is the Chief Executive Officer at BrandFourfive Consulting, a branding company he founded some years ago. <br></br> <br></br>

He is also a published author having written life-changing Christian books such as Christosin's Pen, Eden - GOD’s Eternal Plan, When Momma Says No, and others.
                </p>

              
              </div>
            </Col>
            <Col lg={6}>
              <div
                className={classes.image_div}
                data-aos="fade-left"
                data-aos-easing="ease-out"
                data-aos-duration="700"
              >
                <img
                  className={classes.image}
                  src={pastorImage}
                  alt="about"
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
        <Footer/>

      </section>
    </>
  );
  //END
};

export default HeroFourSection;
