import React, {  useState, useRef } from "react";
import { useForm } from "react-hook-form";
// import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import styled from 'styled-components'

import classes from "./BookContent.module.css";
// import HeroThreeForm from "./HeroThreeForm";
// import CartContext from "../store/cartcontext"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import TheButton from "../Ui/CartButton";
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { GoogleFormProvider, useGoogleForm } from "react-google-forms-hooks";
const FORM_ENDPOINT = ""; // TODO - fill on the later step

// import emailjs, { init } from "@emailjs/browser";

//import { useForm } from "react-hook-form";
//template_d81f5dh
//service_r5eao4m


//  import Consecration from "../../../public/Consecration - Christosin Olalere.pdf"

// const handleSubmit = (e) => {
//   e.preventDefault();
// };





const BookContent = (props) => {
//   super(props) 
//   this.state = {
//     email: '',
//     fname: '',
//     lname: '',
//     option: '',
//     phone: '',
//  }


  const [submitted, setSubmitted] = useState(false);


  // const [formData, setFormData] = useState({})

  //Import environmental varialbles
  const {
    REACT_APP_PRIVATE_KEY,
    REACT_APP_CLIENT_EMAIL,
    REACT_APP_SPREADSHEET_ID,
    REACT_APP_SHEET_ID
  } = process.env

  //creating new object of google spreadsheet



  //end

  // const handleInputChange = (event) =>{
  //   setFormData({...formData,[event.target.name]:event.target.value})
  // }

  




  const formRef = useRef(null)
  const scriptUrl = "https://script.google.com/macros/s/AKfycbwUBTlKQKLoSBbwIS1dqhh0aC5nb_Vp2s9fubs-mXYibEEgjRBTv1q7ldcoY0Sjnx_-/exec"
  const [loading, setLoading] = useState(false)

  const handleSubmittt = (e) =>{
      e.preventDefault()
      setLoading(true)

      fetch(scriptUrl, {
      method: 'POST', 
      mode: 'no-cors', // no-cors, *cors, same-origin
      body: new FormData(formRef.current),

  }).then(res => {
          console.log("SUCCESSFULLY SUBMITTED")
          setLoading(false)
      })
      .catch(err => console.log(err))

}


  const myRequest = (url) => {
    let response;
    try {
      response = axios.post(url,null,null)
    } catch (e) {
      response = e;
    }
    console.log(response)
  }
  // const handleChange = (e) => {
  //   this.setState({ [e.target.name]: e.target.value })
  // }
  

//handleSubmit
const handleSubmitt = (e) => {
  const data = {
    ...this.state
  }

//https://docs.google.com/forms/u/1/d/e/1FAIpQLSd1ZzfTMa2heqlZeI-g06vnvdsV-lXcdQD25oi7LI5AxVHIsQ/formResponse
  //https://docs.google.com/forms/u/1/d/e/1FAIpQLSd6CEk8k8hJ264YonxwxHuB6mHn2_WVILjemqWQaSIO78KmJg/formResponse
  const id = "1FAIpQLSd1ZzfTMa2heqlZeI-g06vnvdsV-lXcdQD25oi7LI5AxVHIsQ" //YOUR FORM ID
  e.preventDefault()
  const formUrl = "https://docs.google.com/forms/d/"+ id     +"/formResponse";
  const queryString = require('query-string')
  const q = queryString.stringifyUrl({
    url: formUrl,
  })
  this.myRequest(q)
}
  const [open, setOpen] = useState(false);
  // const { register, handleSubmit, errors } = useForm(); // initialise the hook
  const onSubmit = async data => {
    console.log(data);
    //await something;
    // if good setOpen(false) to dismiss.
  };

  //const {  handleDownloadBook } = useForm();

  // const [formValue, setFormValue] = useState({
  //   fname: '',
  //   lname: '',
  //   email: '',
  //   city: '',
  //   state: '',
  //   zip: '',
  // });


  // const [showModal, setShowModal] = useState(false);

  // const aboutModal = () => {
  //   setShowModal(true);
  //   console.log("click me")
  // };

  // const closeModal = () => {
  //   setShowModal(false);
  // };

  const [show, setShow] = useState(false);


  const [values, setValues] = useState({
    fname: '', lname: '', email: '', phone: ''
  });
  
  const set = fname => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({...oldValues, [fname]: value }));
    }
  };



  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [option, setOption] = useState('');
  const [phone, setPhone] = useState('');

  

//GOOGLE FORM API
//action="https://docs.google.com/forms/u/1/d/e/1FAIpQLSd6CEk8k8hJ264YonxwxHuB6mHn2_WVILjemqWQaSIO78KmJg/formResponse"







  const handleDownloadBook = (data, e) => {
    e.preventDefault(); // Prevent default submission
    console.log(data);
    this.clear();//this is function i made

    alert('Form successfully download')

  } 

  const handleEmailChange = event => {
    setEmail(event.target.value)
  };

  const handleFirstNameChange = event => {
    setFname(event.target.value)
  };

  const handleLastNameChange = event => {
    setLname(event.target.value)
  };
const handleDropdownChange = event => {
  setOption(event.target.value)
}

const handlePhoneChange = event => {
  setPhone(event.target.value)
}


//Inside Class
 

  const handleSubmit = event => {

 

  event.preventDefault();
  const objt = {fname, lname, email, option, phone}

  axios.post('https://sheet.best/api/sheets/cf4558fa-f273-4393-815b-9f6bce73d362',objt )
  .then(response => {
    console.log(response);
  })

    console.log(email, fname, lname, option, phone);
    alert(`Your state values: \n 
            email: ${email} \n 
            fname: ${fname}\n
            lname: ${lname}\n
            option: ${option}\n
            phone: ${phone}
            You can replace this alert with your process`);


            // const data = {
            //   ...this.state
            // }
                    


  };

  //Layout and structure of the Hero three (Third section) content
  return (
    <div className={classes.dish_content}>
      <div className={classes.dish_image_div}>
        <img className={classes.book_img_div} src={props.src} alt="Books" />
      </div>


      <div className={classes.dish_text_div}>
        <p>{props.name}</p>
      </div>
      <div className={classes.dish_download_div}>
        <p>{props.download}</p>
      </div>

      
      <div className={classes.dish_price_div}>
        <p className="my-auto">{props.free}</p>
        <div className={classes.media_btn}>

        </div>
      </div>



      <div className={classes.desc_container}>

      <h2 className={classes.dish_desci_div}>{props.description}</h2>
      </div>

      <button className={classes.download_btn} onClick={handleShow}>Download for free <i className="bi bi-cloud-download"></i>
      </button>




     
      <Modal size="xl" show={show} onHide={handleClose}>

        <Modal.Header closeButton>
          <Modal.Title>Fill your details to continue</Modal.Title>

        </Modal.Header>
        <Modal.Body>

        <div>
        <iframe style={{
          height:"100%", 
          width:"100%",
        }} src="https://docs.google.com/forms/d/e/1FAIpQLSd6CEk8k8hJ264YonxwxHuB6mHn2_WVILjemqWQaSIO78KmJg/viewform">Download Now</iframe>

        </div>
       
       
      </Modal.Body>
        <Modal.Footer>

        <Modal.Title className={classes.title_color}>Total: Free</Modal.Title>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button  type="submit" className={classes.download_now}   >
          <a href={props.pdf}  download target="_blank" rel="noopener noreferrer" >Download now<i className="bi bi-cloud-download"></i></a> 
          </Button>
        </Modal.Footer>

      </Modal>


    </div>

    
  );
  //END


  
};


export default BookContent




