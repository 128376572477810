import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import classes from "./media.module.css";
import BookContent from "./BookContent";
import Tabs from "./MediaTab";
import Panel from "../../components/Sections/Panel";
import "./styles.css"
// import gep from "../../asset/gep.svg"
import Utterance from "../../asset/bookimg/bts.jpg"

//tdos.jpg
// import Tab from "../../components/Sections/Tabs"
// import PanelMedia from "../../components/Sections/Panel"
import ComingSoonSection from "../../components/Sections/ComingSoonSection";
import StreetPdf from "./Beyond the Shadows.pdf"
// import Consecration from "../../../public/Consecration - Christosin Olalere.pdf"
const text = `""This is one of the greatest Bible studies ever done! It is a Bible Course material \n that will challenge you to more Bible Study, and change your understanding of \n the word forever! \n \n To understand GOD's Eternal Plan, you have to observe what was said in the \n opening words of the Bible, hence this Book, which carries a very robust study \n of Genesis. \n\n In this comprehensive study material, you will be totally transformed by the light of the revelation of GOD's Eternal Plan for Mankind."`


//Data objects to be used in the HeroThree section for rendering dishes information
const dummyListone = [
  {
    id: "d1",
    src: Utterance,
    name: "Beyond the Shadows",
    description:"Every Bible subject must be studied in the light of the Resurrection, that is, how the Resurrection has affected that subject matter. Not all activities or Bible Practices are SYMBOLS. Symbols are those things that represented the person and work of Christ in the Old Testament. In this over 200-page study material, we consider these practices in the light of the Resurrection. We will see; Circumcision, Baptism, The Annointing, The Passover and Holy Communion, The Blood, Dressing and Culture, Tithing, Firstfruits and Giving. And what the Resurrection says about them, all viewed in the light of the love walk. It will be a book that will bring you maturity in all ways!",
    free: "Free",
    pdf: StreetPdf
  },

 
];
//END

const bookDetailPage = () => {
  //Mapping the data from the object above and storing them in a variable
  const dishList1 = dummyListone.map((dish) => (
    <Col lg={4} className={classes.dish_col}>
      <div
        data-aos="fade-up"
        data-aos-easing="ease-out"
        data-aos-duration="700"
      >
        <BookContent
          key={dish.id}
          id={dish.id}
          name={dish.name}
          src={dish.src}
          description={dish.description}
          free={dish.free}
          pdf={dish.pdf}
        />
      </div>
    </Col>
  ));

  return (
    <section id="dishes">
      <Container>
        <Row
          className={`${classes.row} mx-auto`}
          data-aos="fade-up"
          data-aos-easing="ease-out"
          data-aos-duration="700"
        >
          <Col xs={1}>
            <div className={classes.header_div}>
            </div>
          </Col>
        </Row>

        {/* <Row className={classes.row_dish}>{dishList1}</Row>

        <Row>{dishList2}</Row> */}
        <div className={classes.tab_col}>
        <Tabs>
      <Panel title="English">

        <Row className={classes.row_dish}>{dishList1}</Row>

        {/* <Row>{dishList1}</Row> */}
        
      </Panel>
      <Panel title="French">
      <Row className={classes.row_dish}>{dishList1}</Row>

      </Panel>
      <Panel title="Korean">
      <Row className={classes.row_dish}>{dishList1}</Row>
     </Panel>
    </Tabs>
    </div>

    <Row>
      <div>

        {/* <p>Relatedd Books</p> */}
       {/* <ComingSoonSection/>         */}
      </div>
    </Row>
      </Container>
    </section>
  );
  //END
};

export default bookDetailPage;
