import React, { useContext, useState } from "react";
import CartContext from "../store/cartcontext";
import classes from "../Cart/CartItem.module.css";
import ShippingAddress from "./ShippingAddress";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
  const cartCtx = useContext(CartContext);
  const [shippingCost, setShippingCost] = useState(2200); // Default shipping cost
  const [shippingState, setShippingState] = useState("Lagos"); // Default state
  const navigate = useNavigate();  // Corrected the typo here

  const totalAmount = `₦${(cartCtx.totalAmount + shippingCost).toFixed(2)}`;

  const handleAddressUpdate = (newAddress) => {
    setShippingState(newAddress.state); // Update the shipping state
  };

  const handlePlaceOrder = () => {
    // Navigate to the final checkout form, passing the shippingState
    navigate("/checkout-form", { state: { shippingState, shippingCost } }); // Passing state
  };

  return (
    <div className={classes.checkout}>
      <h2 className={classes.header}>Your Cart</h2>
      <div className={classes.items}>
        {cartCtx.items.map((item) => (
          <div className={classes.item} key={item.id}>
            <div className={classes.item_content}>
              <div className={classes.item_img_div}>
                <img
                  className={classes.item_image}
                  src={item.src}
                  alt={item.name}
                />
              </div>
              <div className={classes.item_value}>
                <div className={classes.item_name_div}>
                  <p>{item.name}</p>
                </div>
                <div className={classes.item_info_div}>
                  <p className={classes.price}>₦{item.price.toFixed(2)}</p>
                </div>
              </div>
              <div className={classes.item_buttons}>
                <button onClick={() => cartCtx.removeItem(item.id)}>-</button>
                <p>{item.amount}</p>
                <button onClick={() => cartCtx.addItem({ ...item, amount: 1 })}>
                  +
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <ShippingAddress 
        onUpdate={handleAddressUpdate} 
        shippingCost={shippingCost} 
        setShippingCost={setShippingCost} 
      />

      <div className={classes.total}>
        <span>Total Amount:</span>
        <span>{totalAmount}</span>
      </div>
      <button className={classes.checkout_button} onClick={handlePlaceOrder}>
        Proceed to Checkout
      </button>
    </div>
  );
};

export default Checkout;
