import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import classes from "./HeroSixSection.module.css";
import youtube from "../../asset/youtube.svg"
import telegram from "../../asset/telegram.svg"

const HeroSixSection = () => {
  //Rendering the Testimonial Section
  return (
    <section id="testimonials">
      <div className="bg-color"></div>
      <Container>
        <Row
          className={`${classes.row} mx-auto`}
          data-aos="fade-up"
          data-aos-easing="ease-out"
          data-aos-duration="700"
        >
          <Col xs={12}
            data-aos="fade-up"
            data-aos-easing="ease-out"
            data-aos-duration="700"
            data-aos-delay="150"
          >
            <div className={classes.header_divv}>
              <h2>INSPIRING. WISDOM FILLED. LIFE CHANGING</h2>
              <h4 className={classes.header_text}>Gain access to our amazing <br></br> teachings on faith, prayer, <br></br>increase, GOD's eternal plan <br></br> and much more</h4>
              <div className={classes.social_div}>
              <div className={classes.youtube_div}>
              <img src={youtube} alt="youtube" />
              <a
                  href="https://www.youtube.com/@pastorchristosinolalere1601"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p> Youtube </p>
                </a>              </div>

              <div className={classes.telegram_div}>
              <img src={telegram} alt="telegram" />
              <a
                    href="http://bit.ly/HICCmedia"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p> Telegram </p>
                  </a>              </div>
              </div>

            </div>
          </Col>
        </Row>

        <Row className={classes.row_content}>
          <Col
            lg={4}
            data-aos="fade-up"
            data-aos-easing="ease-out"
            data-aos-duration="700"
            data-aos-delay="150"
          >
            <div className={`${classes.text_div} px-2`}>
              {/* <p className={classes.text_location}>Lagos state</p>
              <p className={classes.text_content}>
                With a Homely Eats meal, you can serve delectable, home-cooked
                meals to your family and friends in any location, anytime.
                anyday!
              </p> */}
            </div>
          </Col>

          
        </Row>
      </Container>
    </section>
  );
  //ENd
};

export default HeroSixSection;
