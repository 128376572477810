import React from "react";
import NavCartButton from "./NavCartButton";
import { Navbar, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Link } from "react-scroll";
import {Link} from "react-router-dom";
import classes from "./TheNavbar.module.css";
import Logo from "../../assets/image/7.png";
import {auth} from "../../firebase/firebase.util";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import TemporaryDrawer from './ToogleMenu';


const TheNavbar = (props, currentUser, hidden) => {


  //Layout and structure of the navbar to be passed to THENAV component
  return (
    <>
      <Navbar
        expand="xl"
        className={`${classes.navbar} fixed-top`}
        data-aos="fade-down"
        data-aos-easing="ease-out"
        data-aos-duration="2000"
      >
        <Navbar.Brand className={classes.navbar_brand}>
          <Link to="/" spy={true} smooth={true} offset={-50} duration={500}>
            <img src={Logo} alt="My logo"></img>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className={classes.toggle}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav  className={`${classes.nav__linkgroup} mx-auto`}>
            <Nav.Link
              className={`${classes.nav__link} ${classes.firstnav__link} me-4`} 
            >
              <Link
                activeClassName={classes.active}
                to="/"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
                Home
              </Link>
            </Nav.Link>

            
            <Nav.Link className={`${classes.nav__link} me-4`}>
              <Link
                activeClassName={classes.active}
                to="/about"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
                About 
              </Link>
            </Nav.Link>


            <Nav.Link className={`${classes.nav__link} me-4`}>
              <Link
                activeClassName={classes.active}
                to="/media"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
                Media
              </Link>
            </Nav.Link>
            <Nav.Link className={`${classes.nav__link} me-4`}>
              <Link
                activeClass={classes.active}
                to="aboutt"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
              </Link>
            </Nav.Link>



            {/* <Nav.Link href="#buttons" className={`${classes.nav__link}`}> */}
            {/* </Nav.Link> */}
          </Nav>
          <NavCartButton onClick={props.onShowCart} />

        </Navbar.Collapse>
      </Navbar>

      {/* {
                        currentUser ? 
                        <div className="user" onClick={() => auth.signOut()}>
                           <span className="signinuser"> 
                              <img src={require("../../asset/user.png")} alt="" className="signin_image"/> 
                          </span> Sign Out
                        </div> :
                        <div className="user">
                            <PersonOutlineOutlinedIcon /> 
                            <span>
                                <Link to ="/signin" className="menu-item">Sign in</Link>
                            </span>

                            <div className="hamburger-menu">
                            <TemporaryDrawer user={currentUser}/>
                            </div>
                        </div>

                        


                    } */}
    </>
  );
  //ENDS
};

export default TheNavbar;
