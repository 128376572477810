import React, { useState } from "react";
import classes from "./ShippingAddress.module.css";

const ShippingAddress = ({ onUpdate, shippingCost, setShippingCost }) => {
  const [showForm, setShowForm] = useState(false); // State to control form visibility
  const [country, setCountry] = useState("Nigeria");
  const [state, setState] = useState("Lagos");
  const [city, setCity] = useState("");
  const [shippingMethod, setShippingMethod] = useState("homeDelivery"); // Default to home delivery

  const handleUpdate = () => {
    onUpdate({ country, state, city });
    // Adjust the shipping cost based on the location (this is just a basic example)
    if (shippingMethod === "homeDelivery") {
      setShippingCost(state === "Lagos" ? 2200 : 3300);
    } else {
      setShippingCost(0);
    }
    setShowForm(false); // Hide form after update
  };

  // List of all states in Nigeria
  const statesInNigeria = [
    "Abia", "Adamawa", "Akwa Ibom", "Anambra", "Bauchi", "Bayelsa", "Benue",
    "Borno", "Cross River", "Delta", "Ebonyi", "Edo", "Ekiti", "Enugu", 
    "FCT", "Gombe", "Imo", "Jigawa", "Kaduna", "Kano", "Katsina", "Kebbi", 
    "Kogi", "Kwara", "Lagos", "Nasarawa", "Niger", "Ogun", "Ondo", "Osun", 
    "Oyo", "Plateau", "Rivers", "Sokoto", "Taraba", "Yobe", "Zamfara"
  ];

  const handleShippingChange = (method) => {
    setShippingMethod(method);
    if (method === "homeDelivery") {
      setShippingCost(state === "Lagos" ? 2200 : 3300);
    } else {
      setShippingCost(0);
    }
  };

  return (
    <div className={classes.shippingAddress}>
      <div className={classes.shippingOptions}>
        <p>Shipping</p>
        <div>
          <input
            type="radio"
            name="shipping"
            checked={shippingMethod === "homeDelivery"}
            onChange={() => handleShippingChange("homeDelivery")}
          />
          <label>
            Shipping to {state}, {country}: ₦{shippingCost.toLocaleString()}
          </label>
        </div>
        <div>
          <input
            type="radio"
            name="shipping"
            checked={shippingMethod === "pickup"}
            onChange={() => handleShippingChange("pickup")}
          />
          <label>Pick at the Information Centre in Church on Sunday/Wednesday</label>
        </div>
        <p>Shipping to <strong>{state}</strong>.</p>
      </div>
      <button
        className={classes.changeAddressButton}
        onClick={() => setShowForm((prev) => !prev)}
      >
        Change address <span role="img" aria-label="truck">🚚</span>
      </button>

      {showForm && (
        <div className={classes.addressForm}>
          <select
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            className={classes.selectBox}
          >
            <option value="Nigeria">Nigeria</option>
            {/* Add more countries as needed */}
          </select>
          <select
            value={state}
            onChange={(e) => setState(e.target.value)}
            className={classes.selectBox}
          >
            {statesInNigeria.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
          <input
            type="text"
            value={city}
            placeholder="City"
            onChange={(e) => setCity(e.target.value)}
            className={classes.cityInput}
          />
          <button onClick={handleUpdate} className={classes.updateButton}>
            Update
          </button>
        </div>
      )}
    </div>
  );
};

export default ShippingAddress;
