import React, { useState, useEffect } from 'react';
import { Row, Container, Col } from "react-bootstrap";
import TheButton from "../../src/components/Ui/TheButton";
// import btn from '../../src/components/Ui'
import "bootstrap/dist/css/bootstrap.min.css";
// import classes from '../../src/components/SectionComponents'
// import classes from "../../src/components/Section/HeroFourSection.module.css";
import classes from "../components/Sections/HeroFourSection.module.css";

import aboutImage from "../assets/image/about-image.webp";
import Modal from "../../src/components/Ui/Modal";
import kerusso from '../assets/image/eldoxadd.jpg'
import apple from '../assets/image/apple_logo.png'
import spotify from '../assets/image/spotify_logo.png'
import audiomack from '../assets/image/audiomack_logo.png'
import youtube from '../assets/image/Youtube_logo.png'
import { FaDownload, FaPlay } from 'react-icons/fa';
import classesBtn from "./Eliass.module.css";
import Footer from '../components/Footer/Footer'
import TheFooter from '../components/Footer/TheFooter'

const Eldoxa = () => {
  const [showModal, setShowModal] = useState(false);
  const streamingServices = [
    { name: "YouTube", link: "https://youtu.be/cj_jADuVgfU?si=I_ilkZwrKeH_L8nE", icon: youtube },
    { name: "Spotify", link: "https://open.spotify.com/track/1btGTuEWFSG8H3Rz07G93h?si=c8e4667027eb425b", icon: spotify },
    { name: "Apple Music", link: "https://music.apple.com/us/album/el-doxa-god-of-glory-ep/1746384057", icon: apple },
    { name: "Audiomack", link: "https://audiomack.com/pastorchristosin/song/el-doxa-god-of-glory", icon: audiomack },
    // Add more services as needed
  ];
  const [responsiveStyle, setResponsiveStyle] = useState({ marginTop: '10%' });

  const aboutModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    function handleResize() {
        if (window.innerWidth <= 480) {
            setResponsiveStyle({ marginTop: '16%' });
        } else if (window.innerWidth <= 768) {
            setResponsiveStyle({ marginTop: '25%' });
        } else {
            setResponsiveStyle({ marginTop: '10%' });
        }
    }

    // Set the initial style
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
}, []);


  //Rendering the About section and the About Modal
  return (
    <>
      
      <section className={classes.rower} id="elias">

        <Container>
        <Row style={responsiveStyle} className={`${classes.row} mx-auto`}>
            <Col
              lg={6}
              data-aos="fade-right"
              data-aos-easing="ease-out"
              data-aos-duration="700"
            >
              <div className={classes.text_div}>
              <h2>NOW AVAILABLE FOR DOWNLOAD</h2>

                {/* <h2 className={classes.text_header}>About us</h2> */}
                <p className={classes.text_content}>
                The Wait is Over! 

🎼 EL-DOXA: GOD OF GLORY is officially here, and it's time to let the world know!

With each note, each chorus, it reignites our faith and reminds us of the GOD OF GLORY who can turn any situation around.

Are you ready to be uplifted, inspired, and moved to your core? Then don't waste another moment! 


Download NOW! 

                </p>
                <div className={classes.button_div}>
                  {/* <TheButton onClick={aboutModal}>See more</TheButton> */}
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div
                className={classes.image_div}
                data-aos="fade-left"
                data-aos-easing="ease-out"
                data-aos-duration="700"
              >
                <img
                  className={classes.image}
                  src={kerusso}
                  alt="about"
                ></img>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
  <Col lg={12} className="text-center">
  <button className={`${classesBtn.joinButton} ${classesBtn.downloadButton}`} onClick={() => window.location.href = 'https://vanguardbackend-pih0.onrender.com/download/audio-1715901590522.mp3'}>
  <FaDownload /> DOWNLOAD
</button>

<button className={`${classesBtn.joinButton} ${classesBtn.listenButton}`} onClick={() => window.location.href = 'https://push.fm/fl/el-doxa-god-of-glory-pastor-christosin-olalere'}>
  <FaPlay /> Listen Here
</button>

  </Col>
</Row>
          <Row className={`${classes.row} mx-auto`}>
            <Col>
              <div className={classes.streamingButtons}>
                {streamingServices.map((service, index) => (
                  <a
                    key={index}
                    href={service.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.streamingButton}
                  >
                    <img src={service.icon} alt={service.name} />
                  </a>
                ))}
              </div>
            </Col>
          </Row>

        </Container>
        <TheFooter />

      </section>
    </>
  );
  //END
};

export default Eldoxa;
