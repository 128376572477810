import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";  // Import the useNavigate hook
import CartItem from "./CartItem";
import Modal from "../Ui/Modal";
import CartButton from "../Ui/CartButton";
import classes from "./Cart.module.css";
import CartContext from "../store/cartcontext";
import arrow from "../../asset/arrow.svg";
import arrow2 from "../../asset/arrow-purple.svg";
import CartClose from "../Ui/CartClose";

const Cart = (props) => {
  const cartCtx = useContext(CartContext);
  const navigate = useNavigate();  // Initialize the useNavigate hook

  const totalAmount = `₦${cartCtx.totalAmount.toFixed(2)}`;
  const hasItems = cartCtx.items.length > 0;

  const onAddHandler = (item) => {
    cartCtx.addItem({ ...item, amount: 1 });
  };

  const onRemoveHandler = (id) => {
    cartCtx.removeItem(id);
  };

  const cartItems = cartCtx.items.map((item) => (
    <CartItem
      name={item.name}
      amount={item.amount}
      price={item.price}
      download={item.download}
      key={item.id}
      src={item.src}
      onAdd={onAddHandler.bind(null, item)}
      onRemove={onRemoveHandler.bind(null, item.id)}
    />
  ));

  // Handle View Cart button click
  const viewCartHandler = () => {
    props.onCloseCart();  // Close the modal
    navigate("/checkout");  // Navigate to the cart page
  };

  return (
    <Modal onCloseCart={props.onCloseCart}>
      <div className={classes.items}>
        <div className={classes.item_group}>{cartItems}</div>

        <div className={`${classes.amount} `}>
          <p>Subtotal:</p>
          <p>{totalAmount}</p>
        </div>
        <div className={classes.buttons}>
          <CartClose
            onClick={props.onCloseCart}
            className={` ${classes.btn_style} me-2`}
          >
            Close
          </CartClose>

          <CartButton
            onClick={viewCartHandler}  // Use the viewCartHandler function
            className={` ${classes.btn_style} me-2`}
          >
            View Cart
            <img className={classes.arrow_white1} src={arrow2} alt="arrow" />
          </CartButton>

          {hasItems && (
            <CartButton className={classes.btn_style2} onClick={props.onOrder}>
              Proceed to Checkout
              <img className={classes.arrow_white} src={arrow} alt="arrow" />
            </CartButton>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Cart;
