import React from 'react';
import Slider from 'react-slick';
import classes from './AlbumAnnouncement.module.css';
import playIcon from '../../assets/icons/stream.svg'; // Path to your play icon

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logoImage from '../../assets/image/kerrusso.jpeg'; // Path to your logo image
import HAB from "../../asset/HAB.png";

// Updated data with isLargeImage property
const slidesData = [
  {
    title: "The Album: Kērussō is out",
    description1: "After touching over 105,000 lives with the first five singles, the full album is now available, featuring three brand-new songs. Get ready to experience something like never before!",
    description2: "These three phenomenal songs bring the album to a powerful and inspiring finale. Get ready to be blessed by these powerful sounds.",
    image: logoImage,
    showButton: true,
    isLargeImage: false
  },
  {
    title: "Ambidextrous Christianity", 
    description1: "This Book will be released later this year: AMBIDEXTROUS CHRISTIANITY! It's a book that will transform the understanding of the believer in His assignment and the call of GOD upon him. AMBIDEXTROUS CHRISTIANITY has always been GOD'S plan, and more than ever before, it will be the order of the day in the 21st Century. AMBIDEXTROUS CHRISTIANITY will be strong believers trained in the Word, prayer, Ministry, and the things of the Spirit, yet very strong in their careers and natural endeavours as platforms to establish the rule of GOD in the world- They will be TOTAL MEN.",
    description2: "The saints are the ones to primarily do the work of ministry, which is primarily the Ministry of Reconciliation, and all that goes into it.  There will need to be platforms for this ministry, with the training to function in that capacity. This book will open the eyes of believers to who they have been called to be- light in the dark places of this world, and the training that goes with it. WATCH OUT!",
    image: HAB,
    showButton: false,
    isLargeImage: true
  },
];

const AlbumAnnouncement = ({ onListenNow }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
  };

  return (
    <Slider {...settings} className={classes.slider}>
      {slidesData.map((slide, index) => (
        <div key={index}>
          <div className={classes.announcement}>
            <div className={classes.textSection}>
              <h2 className={classes.title}>{slide.title}</h2>
              <p className={classes.description}>{slide.description1}</p>
              <p className={classes.description}>{slide.description2}</p>
              {slide.showButton && (
                <button className={classes.listenButton} onClick={onListenNow}>
                  <img src={playIcon} alt="Listen" className={classes.playIcon} />
                  Listen Now
                </button>
              )}
            </div>
            <div className={classes.logoContainer}>
              <img
                src={slide.image}
                alt={slide.title}
                className={`${classes.logo} ${slide.isLargeImage ? classes.largeLogo : ''}`}
              />
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default AlbumAnnouncement;
