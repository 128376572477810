import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import classes from "./media.module.css";
import BookContent from "./BookContent";
import Tabs from "./MediaTab";
import Panel from "../../components/Sections/Panel";
import "./styles.css"
// import gep from "../../asset/gep.svg"
import Utterance from "../../asset/bookimg/tlador.jpg"

//tdos.jpg
// import Tab from "../../components/Sections/Tabs"
// import PanelMedia from "../../components/Sections/Panel"
import ComingSoonSection from "../../components/Sections/ComingSoonSection";
import StreetPdf from "./The Law and The Doctrine of Righteousness.pdf"
// import Consecration from "../../../public/Consecration - Christosin Olalere.pdf"
const text = `""This is one of the greatest Bible studies ever done! It is a Bible Course material \n that will challenge you to more Bible Study, and change your understanding of \n the word forever! \n \n To understand GOD's Eternal Plan, you have to observe what was said in the \n opening words of the Bible, hence this Book, which carries a very robust study \n of Genesis. \n\n In this comprehensive study material, you will be totally transformed by the light of the revelation of GOD's Eternal Plan for Mankind."`


//Data objects to be used in the HeroThree section for rendering dishes information
const dummyListone = [
  {
    id: "d1",
    src: Utterance,
    name: "The Law and The Doctrine of Righteousness",
    description:"Moses is a central figure in the understanding of the subject the LAW and RIGHTEOUSNESS, so is his New Testament counterpart, the Apostle Paul! Their revelatory and thought-provoking writings are springboards that call for study, for accurate understanding. In this powerful, robust, about 300-paged study material. I took a journey, an investigative study, to give accurate understanding in this study matter, crucial to the Christian Faith. This is a study that will bring revelation that will directly imapact our daily living!",
    free: "Free",
    pdf: StreetPdf
  },

 
];
//END

const bookDetailPage = () => {
  //Mapping the data from the object above and storing them in a variable
  const dishList1 = dummyListone.map((dish) => (
    <Col lg={4} className={classes.dish_col}>
      <div
        data-aos="fade-up"
        data-aos-easing="ease-out"
        data-aos-duration="700"
      >
        <BookContent
          key={dish.id}
          id={dish.id}
          name={dish.name}
          src={dish.src}
          description={dish.description}
          free={dish.free}
          pdf={dish.pdf}
        />
      </div>
    </Col>
  ));

  return (
    <section id="dishes">
      <Container>
        <Row
          className={`${classes.row} mx-auto`}
          data-aos="fade-up"
          data-aos-easing="ease-out"
          data-aos-duration="700"
        >
          <Col xs={1}>
            <div className={classes.header_div}>
            </div>
          </Col>
        </Row>

        {/* <Row className={classes.row_dish}>{dishList1}</Row>

        <Row>{dishList2}</Row> */}
        <div className={classes.tab_col}>
        <Tabs>
      <Panel title="English">

        <Row className={classes.row_dish}>{dishList1}</Row>

        {/* <Row>{dishList1}</Row> */}
        
      </Panel>
      <Panel title="French">
      <Row className={classes.row_dish}>{dishList1}</Row>

      </Panel>
      <Panel title="Korean">
      <Row className={classes.row_dish}>{dishList1}</Row>
     </Panel>
    </Tabs>
    </div>

    <Row>
      <div>

        {/* <p>Relatedd Books</p> */}
       {/* <ComingSoonSection/>         */}
      </div>
    </Row>
      </Container>
    </section>
  );
  //END
};

export default bookDetailPage;
