import React, { useState, useEffect } from 'react';
import { Row, Container, Col } from "react-bootstrap";
import TheButton from "../components/Ui/TheButton";
// import btn from '../../src/components/Ui'
import "bootstrap/dist/css/bootstrap.min.css";
// import classes from '../../src/components/SectionComponents'
// import classes from "../../src/components/Section/HeroFourSection.module.css";
import classes from "../components/Sections/HeroFourSection.module.css";

import aboutImage from "../assets/image/about-image.webp";
import Modal from "../components/Ui/Modal";
import kerusso from '../assets/image/purpose.jpg'
import apple from '../assets/image/apple_logo.png'
import spotify from '../assets/image/spotify_logo.png'
import audiomack from '../assets/image/audiomack_logo.png'
import youtube from '../assets/image/Youtube_logo.png'
import { FaDownload, FaPlay } from 'react-icons/fa';
import classesBtn from "./Eliass.module.css";
import Footer from '../components/Footer/Footer'
import TheFooter from '../components/Footer/TheFooter'

const Purpose = () => {
  const [showModal, setShowModal] = useState(false);
  const streamingServices = [
    { name: "YouTube", link: "https://www.youtube.com/watch?v=ZQRkI6XPNi0", icon: youtube },
    { name: "Spotify", link: "https://open.spotify.com/track/1btGTuEWFSG8H3Rz07G93h?si=c8e4667027eb425b", icon: spotify },
    { name: "Apple Music", link: "https://music.apple.com/us/album/el-doxa-god-of-glory-ep/1746384057", icon: apple },
    { name: "Audiomack", link: "https://audiomack.com/pastorchristosin/song/el-doxa-god-of-glory", icon: audiomack },
    // Add more services as needed
  ];
  const [responsiveStyle, setResponsiveStyle] = useState({ marginTop: '10%' });

  const aboutModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    function handleResize() {
        if (window.innerWidth <= 480) {
            setResponsiveStyle({ marginTop: '16%' });
        } else if (window.innerWidth <= 768) {
            setResponsiveStyle({ marginTop: '25%' });
        } else {
            setResponsiveStyle({ marginTop: '10%' });
        }
    }

    // Set the initial style
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
}, []);


  //Rendering the About section and the About Modal
  return (
    <>
      
      <section className={classes.rower} id="elias">

        <Container>
        <Row style={responsiveStyle} className={`${classes.row} mx-auto`}>
            <Col
              lg={6}
              data-aos="fade-right"
              data-aos-easing="ease-out"
              data-aos-duration="700"
            >
              <div className={classes.text_div}>
              <h2>NOW AVAILABLE FOR DOWNLOAD</h2>

                {/* <h2 className={classes.text_header}>About us</h2> */}
                <p className={classes.text_content}>
                Every note of this sound is encapsulated with the consciousness of your divine purpose, reminding you about your earthly assignment. 

Are you ready to awaken or fan to flame the consciousness of your purpose? Then this is the right song for you! 


Download NOW! 

                </p>
               
              </div>
            </Col>
            <Col lg={6}>
              <div
                className={classes.image_div}
                data-aos="fade-left"
                data-aos-easing="ease-out"
                data-aos-duration="700"
              >
                <img
                  className={classes.image}
                  src={kerusso}
                  alt="about"
                ></img>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
  <div className={classes.button_div}>         
  <Col lg={12} className="text-center">
  <button className={`${classesBtn.joinButton} ${classesBtn.downloadButton}`} onClick={() => window.location.href = 'https://vanguardbackend-pih0.onrender.com/download/audio-1715901495633.mp3'}>
  <FaDownload /> DOWNLOAD
</button>

<button className={`${classesBtn.joinButton} ${classesBtn.listenButton}`} onClick={() => window.location.href = 'https://push.fm/fl/act-on-purpose-christosin-olalere'}>
  <FaPlay /> Listen Here
</button>

  </Col>
  </div>
</Row>
          <Row className={`${classes.row} mx-auto`}>
          
          </Row>

        </Container>
        <TheFooter />

      </section>
    </>
  );
  //END
};

export default Purpose;
