import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import classes from "./BookDownloads.module.css";
import { Link, useNavigate } from "react-router-dom";
import stand from "../../asset/stand.svg";
// import HeroThreeForm from "./HeroThreeForm";
// import CartContext from "../store/cartcontext";


const linkRouter = [
  {
    id: "d1",
    router: "book/tdos-tfos"
  },

  {
    id: "d2",
    router: "book/media"
  }
]

const routerList = linkRouter.map((routed) => (
  <Link 
  id= {routed.id}
  routed={routed.id}/>
))


const BookDownloadsContent = (props) => {
  // Using useContext hooks
  //END

  
  


  //Converting prices to two decimal places

  const price = `NGN ${props.price?.toFixed(2)}`;

  const message = () => {
    console.log("Downloading...");
  }
  //END

  //Adding data values to be managed to the useContext
//   const onAddToCartHandler = (amount) => {
//     cartCtx.addItem({
//       id: props.id,
//       name: props.name,
//       amount: amount,
//       price: props.price,
//       src: props.src,
//       download: props.download,
//     });
//   };
  //END

  //Layout and structure of the Hero three (Third section) content
  return (
    <div className={classes.dish_content}>
      <div className={classes.dish_image_div}>
        <img src={props.src} alt="Books" />
      </div>
      <div className={classes.dish_text_div}>
        <p>{props.name}</p>
      </div>
      {/* <div className={classes.dish_download_div}>
        <p>{props.download}</p>
      </div> */}


      <div className={classes.dish_price_div} >
        <div className={classes.download_container}>
      <Link style={{
        textAlign: "center",
      }} to={props.readmore} className={classes.read_div} onClick={message}>Download</Link>

      {/* <a href={props.pdf} target="_blank" rel="noopener noreferrer" download>Download</a>

        <div className={classes.dish_img_div}>
        <img src={props.downloadclick} alt="Cart" />
        </div> */}
        </div>

      </div>


      <div className={classes.dish_stand_div}>
      {/* <img className={classes.stand_div} src={stand} alt="stand" /> */}
      </div>
      {/* <div className={classes.dish_read_div}>
        <p onClick={message}>{props.readmore}</p>
      </div> */}
      {/* <div className={classes.dish_price_div}>
        <p className="my-auto">{price}</p>
        <HeroThreeForm onAddToCart={onAddToCartHandler} />
      </div> */}
    </div>
  );
  //END
};

export default BookDownloadsContent;
