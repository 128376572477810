import salomepdf from "../consecration/Consecration.pdf"
import lionPdf from "../lionPrey/The Lion's Prey.pdf"
import gep from "../../asset/bookimg/gep.jpg"
// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
      name: "GOD's Eternal Plan",
      price: 30,
      _id: "GOD'S-Eternal-Plan",
      description: "Have a carpet-like lawn without any work.",
      pdf: salomepdf,
      bookImage: gep,
      priceId: "Read More",
      aware: "This is just bullshit",
      download: "Download"
    },
    {
      name: "Leaf Raking",
      price: 50,
      _id: "lion",
      description: "Remove those pesky dead tree parts from your property.",
      pdf: lionPdf,
      bookImage: gep


    },
    {
      name: "Weeding",
      price: 50,
      _id: "3",
      description: "Don't let the invaders ruin your yard.",
      bookImage: gep

    },
    {
      name: "Sprinkler repair",
      price: 100,
      _id: "4",
      description: "Keep your irrigation system top-notch.",
      bookImage: gep

    }
  ];
  