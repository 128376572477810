import React, { useEffect, useRef, useState } from 'react';
import classes from "./HeroSection.module.css";
import { Row, Col, Container } from "react-bootstrap";
import AlbumAnnouncement from './AlbumAnnouncement';
import "bootstrap/dist/css/bootstrap.min.css";
import pentecost from '../../assets/image/Pentecost.png';
import purpose from '../../assets/image/purpose.jpg';
import eldoxa from '../../assets/image/eldoxadd.jpg';
import elias from '../../assets/image/elias_main.jpg';
import anointed from '../../assets/image/annointed_jesus.jpeg';
import direction from '../../assets/image/flames_of_direction.jpeg';
import footstool from '../../assets/image/footstool.jpeg';
import healer from '../../assets/image/great_healer.jpeg';
import downloadButton from '../../assets/icons/download.svg';
import streamButton from '../../assets/icons/stream.svg';
import AOS from 'aos';

const HeroSection = () => {
  const [isHeroVisible, setHeroVisible] = useState(false);
  const audioRef = useRef();

  useEffect(() => {
    AOS.init();
    audioRef.current = new Audio('../../../public/elias_song.mp3');
    audioRef.current.muted = true;
    audioRef.current.play().catch(error => console.error('Error attempting to play audio:', error));

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = "";
      }
    };
  }, []);

  const handleListenNow = () => {
    setHeroVisible(true);
    document.getElementById('hero').scrollIntoView({ behavior: 'smooth' });
  };

  const albums = [
    { id: 1, title: "PENTECOST - The Harvest", artist: "Pastor Christosin Olalere", imageUrl: pentecost, songUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1717404895170.mp3", buttonType: "download" },
    { id: 2, title: "ACT ON PURPOSE", artist: "Pastor Christosin Olalere", imageUrl: purpose, songUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1715901495633.mp3", buttonType: "download" },
    { id: 3, title: "EL DOXA - God of Glory", artist: "Pastor Christosin Olalere", imageUrl: eldoxa, songUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1715901590522.mp3", buttonType: "download" },
    { id: 4, title: "ELIAS - Man of Fire", artist: "Pastor Christosin Olalere", imageUrl: elias, songUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1715901727267.mp3", buttonType: "download" },
    { id: 5, title: "Anointed Jesus", artist: "Pastor Christosin Olalere", imageUrl: anointed, songUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1718868489593.mp3", buttonType: "download" },
    { id: 6, title: "Flames of Direction", artist: "Pastor Christosin Olalere", imageUrl: direction, songUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1718868645096.mp3", buttonType: "download" },
    { id: 7, title: "Great Healer", artist: "Pastor Christosin Olalere", imageUrl: healer, songUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1718868573330.mp3", buttonType: "download" },
    { id: 8, title: "Footstool", artist: "Pastor Christosin Olalere", imageUrl: footstool, songUrl: "https://vanguardbackend-pih0.onrender.com/download/audio-1719067429474.mp3", buttonType: "download" },
  ];

  return (
    <>
      {!isHeroVisible ? (
        <AlbumAnnouncement onListenNow={handleListenNow} />
      ) : (
        <section className={classes.hero} id="hero">
          <Container fluid>
            <Row className="justify-content-center">
              <Col md={12}>
                <h2 data-aos-duration="700" data-aos="fade-up" data-aos-easing="ease-out">
                  Songs Alert!
                </h2>
              </Col>
            </Row>
            <Row className="justify-content-center">
              {albums.map(album => (
                <Col lg={4} md={6} sm={12} key={album.id}>
                  <div className={classes.albumCard} data-aos="fade-right" data-aos-easing="ease-out" data-aos-duration="700">
                    <img src={album.imageUrl} alt={album.title} className={classes.albumImage} />
                    <div className={classes.albumInfo}>
                      {album.buttonType === 'download' ? (
                        <button className={`${classes.actionButton} ${classes.downloadButton}`} onClick={() => window.open(album.songUrl, '_blank')}>
                          <img src={downloadButton} alt="Download" style={{ width: '24px', height: '24px', padding: '3px 6px' }} />
                          Download Now
                        </button>
                      ) : (
                        <button className={`${classes.actionButton} ${classes.streamButton}`} onClick={() => window.open(album.songUrl, '_blank')}>
                          <img src={streamButton} alt="Stream" style={{ width: '24px', height: '24px', padding: '3px 6px' }} />
                          Stream Now
                        </button>
                      )}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default HeroSection;
